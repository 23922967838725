import React from "react";
import { Helmet } from "react-helmet";

function About() {




  return (
    <div className="pt-64 textblock center cardST" style={{ maxWidth: '1800px' }} alt="Vi erbjuder service och helhetslösning inom kvarlåtenskap försäljning av arvegods dödsbo och bostadstömning samt välgörenhet ">
      <Helmet >
        <title>
          Om oss på Ayalin
        </title>
        <meta
          name="Beskrivning av vårt verksamhet"
          content="vårt ide och organisation"
        />

      </Helmet>
      <h2 className="pb-32">Några ord om oss</h2>
      <p className="aboutImg center2 pt-8 pb-8" />
      <h3 className="center2 pb-16"  >Vår idé</h3>
      <p className="pb-32 textblock">
        <span id="capital">A</span>yalin är ett litet företag som drivs av högkompetenta personer som bestämde sig för att starta sitt eget företag år 2020. Vår verksamhet fokuserar på att köpa bohag, hustömning och förbereda fastigheter för försäljning eller uthyrning.

        Vi förstår att många saker har ett sentimentalt värde och är fulla av minnen för dig, och därför vill vi att de ska ge tröst åt andra människor. Vi skickar saker till Ukraina för att hjälpa människor att få den värme och komfort som de har förlorat.
      </p>

      <p className="enviro center2 pt-8 pb-8" />
      <h3 className="center2 pb-16"  >Vår arbetssätt</h3>
      <p className="pb-32 textblock">
        <span id="capital">A</span>lgoritmen för vårt arbete är mycket enkel. Vi inleder med ett kostnadsfritt besök hos dig där vi skapar en arbetsplan, väljer en lämplig tidpunkt för arbetet och noterar alla dina önskemål. Vi kan transportera de föremål du vill behålla till den plats du väljer. De föremål som har ett högt värde överförs till auktionshuset.

        När vi har genomfört arbetet kommer vi att ge dig ett erbjudande som inkluderar arbetsomfattningen, kostnaden för arbetet och kostnaden för de föremål som kan säljas. Försäljningen sker genom flera auktioner, inklusive lokala auktioner.
      </p>



    </div>

  );
}

export default About;
