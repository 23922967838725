
import BokaTidMobile from "../BokaTid/BokaTidMobile/BokaTidMobile";
import BokaTidMain from "./BokaTidMain/BokaTidMain";
import React, { useEffect, useState } from 'react';

export default function BokaTid() {



    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 868;

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);



    return (
        <div className='container'>
            {isMobile ? <BokaTidMobile /> : <BokaTidMain />}
        </div>
    );
}

