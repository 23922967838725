import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from 'react-hook-form';
import React, { useState, useRef } from "react";
import { TelefonMain } from './TelefonMain';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group'
import Spinner from '../../../components/UI/Spinner';
import { Helmet } from "react-helmet";

export const tid = ['07.00', '08.00', '09.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00', 'Vet ej just nu'];
export const boarea = ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100', '120', '150', '200 eller mer'];
export const antalrum = ['1', '2', '3', '4', '5', '6', '7', '8 eller mer'];

export default function BokaTidMain() {
    const form = useForm({
        defaultValues: {
            name: "",
            message: "",
            phone: "",
            email: "",
            createdAt: "",
            antalrum: "",
            boarea: "",
            hour: "",
            store: "",
            store2: ""
        }
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();

    const onSubmit = (data) => {
        // Handle form submission
    };

    const navigate = useNavigate();



    const [loading, setLoading] = useState(false);

    const nodeRef = useRef(null);
    const recaptcha = useRef();
    const [alert, setAlert] = useState(false);

    const submitEmail = handleSubmit(async (data, data2) => {
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
            setAlert(true);
        } else {
            const verifyResponse = await fetch("https://ayalin.se/verify", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ captchaValue }),
            });

            const verifyData = await verifyResponse.json();

            if (verifyData.success) {
                setLoading(true);
                try {
                    const sendResponse = await fetch("https://ayalin.se/send", {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json",
                        },
                        body: JSON.stringify({ data }),
                    });

                    onSubmit({
                        name: data.name,
                        message: data.message,
                        phone: data.phone,
                        email: data.email,
                        createdAt: data.createdAt,
                        antalrum: data.antalrum,
                        boarea: data.boarea,
                        hour: data.hour,
                        store: data.store,
                        store2: data.store2,
                    });

                    navigate("/confirm");
                } catch (error) {
                    // Handle error
                }
            }
        }
    });

    const [spanclick, setSpanclick] = useState(false);
    const [checked2, setChecked2] = React.useState(false);
    const handleChange2 = () => {
        setChecked2(!checked2);
    };
    const [checked, setChecked] = React.useState(false);
    const handleChange = () => {
        setChecked(!checked);
    };


    function onChange(value) {
        // Handle CAPTCHA change
    }

    const handleButtonClick = async (eventName, route) => {
        try {
            await fetch(`http://localhost:5000${route}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eventName,
                    route,
                }),
            });
        } catch (error) {
            console.error('Error sending CAPI request:', error);
        }
    }

    return (<>
        <Helmet >
            <title>
                Kom i kontakt med oss.
            </title>
            <meta
                name="Skicka en förfråga eller ring oss"
                content=" Kom i konktakt med oss här eller boka tid för ett besök samt ringa oss"
            />
        </Helmet>
        <div className='container'>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <h2 className='center pt-64'>
                        Kom i kontakt med oss
                    </h2>
                    <section className='pt-64 pb-32 mc flex'>
                        <section className="column cardL pt-32 center">
                            <div className="center2" style={{ width: 400 }}>
                                <p className='mt-8 mb-8 center2 reactIconMail' />
                                <h3 className={"center3 pb-16"}>Skicka iväg en förfråga</h3>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <label htmlFor="name">Ditt namn</label>
                                        <input
                                            id="name"
                                            className='mt-8'
                                            style={{ width: 355 }}
                                            {...register("name", {
                                                required: true,
                                            })}
                                        />
                                        {errors.name?.type === "required" && (
                                            <p className='description error'>Vänligen skriv ditt namn</p>
                                        )}
                                        {errors.name?.type === "minLength" && (
                                            <p className='description error'>The name should have at least 1 character</p>
                                        )}
                                        {errors.name?.type === "matchPattern" && (
                                            <p className='description error'>Name must contain only letters, numbers, and _</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            id="email"
                                            className='mt-8'
                                            style={{ width: 355 }}
                                            {...register("email", {
                                                required: "Vänligen skriv din e-mail",
                                                validate: {
                                                    minLength: (m) => m.length >= 4 || "The email should have at least 5 characters",
                                                    maxLength: (v) => v.length <= 55 || "The email should have at most 50 characters",
                                                    matchPattern: (v) =>
                                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                        "Email address must be a valid address",
                                                },
                                            })}
                                        />
                                        <p className='description error pt-8'>
                                            {errors.email && <>{errors.email.message}</>}
                                        </p>
                                    </div>
                                    <div>
                                        <label className='mb-16'>Telefonnummer</label>
                                        <input
                                            className='mt-8'
                                            style={{ maxWidth: 355 }}
                                            role='phone'
                                            {...register('phone', {
                                                required: 'Vänligen fyll i telefonnummer',
                                                maxLength: {
                                                    value: 13,
                                                    message: 'För långt telefonnummer',
                                                },
                                            })}
                                        />
                                        <p className='description error pt-8'>
                                            {errors.phone && <>{errors.phone.message}</>}
                                        </p>
                                    </div>
                                    <div>
                                        <label htmlFor="createdAt">Önskad datum</label>
                                        <input
                                            style={{ width: 355 }}
                                            className='mt-8'
                                            id="createdAt"
                                            type="date"
                                            role='date'
                                            {...register('createdAt',
                                                {
                                                    required: 'Vänligen fyll i datum'
                                                }
                                            )}
                                        />
                                        <p className='description error pt-8'>
                                            {errors.createdAt && <>{errors.createdAt.message}</>}
                                        </p>
                                    </div>
                                    <label htmlFor="hour">Önskad tid</label>
                                    <div className='center2 center'>
                                        <select style={{ width: 400 }}
                                            id="select"
                                            className='mt-8'
                                            {...register("hour", { required: 'Vänligen välj önskad tid' })}>
                                            <option value=''>--Välj tid nedan--</option>
                                            {tid.map((item) => {
                                                return (
                                                    <option key={item}>
                                                        {item}
                                                    </option>
                                                )
                                            })
                                            }
                                        </select>
                                        <p className='description error pt-8'>
                                            {errors.hour && <>{errors.hour.message}</>}
                                        </p>
                                    </div>
                                    <div className='center2'>
                                        <h3 className={"center3 pt-32 pb-8"}>Utrymmen som ska bortforsas</h3>
                                        <div className='center2 mt-8  '>
                                            <div>
                                                <label htmlFor="createdAt">Antal rum</label>
                                            </div>
                                            <select style={{ width: 400 }}
                                                id="select"
                                                className='mt-8'
                                                {...register("antalrum", { required: 'Vänligen välj antal rum' })}>
                                                <option id="dropdown" value=''>--Välj antal rum--</option>
                                                {antalrum.map((item) => {
                                                    return (
                                                        <option id="dropdown" key={item}>
                                                            {item}
                                                        </option>
                                                    )
                                                })
                                                }
                                            </select>
                                            <p className='description error pt-8'>
                                                {errors.antalrum && <>{errors.antalrum.message}</>}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label htmlFor="createdAt">Uppskattad area på utrymmed</label>
                                        </div>
                                        <select style={{ width: 400 }}
                                            id="select"
                                            className='mt-8'
                                            {...register("boarea", { required: 'Vänligen välj boarea' })}>
                                            <option value=''>--Välj boarea nedan--</option>
                                            {boarea.map((item) => {
                                                return (
                                                    <option key={item}>
                                                        {item}
                                                    </option>
                                                )
                                            })
                                            }
                                        </select>
                                        <p className='description error pt-8'>
                                            {errors.boarea && <>{errors.boarea.message}</>}
                                        </p>
                                    </div>
                                    <label htmlFor="store">Finns förråd i huset?</label><br />
                                    <input
                                        role='store'
                                        id="store"
                                        className='checkbox'
                                        type="checkbox"
                                        {...register('store')}
                                    />
                                    <div>
                                        <label>Finns källare i huset?</label><br />
                                        <input
                                            id="store2"
                                            type="checkbox"
                                            className='checkbox'
                                            {...register('store2')}
                                        />
                                    </div>
                                    <div className='pt-32' >
                                        <label htmlFor="message">Ditt meddelande</label>
                                        <textarea
                                            style={{ width: 400 }}
                                            rows={6}
                                            cols={48}
                                            className='mt-8'
                                            id="message"
                                            {...register('message', {
                                                required: 'Vänligen skriv ett meddelnande',
                                                minLength: {
                                                    value: 13,
                                                    message: 'För kort meddelande',
                                                },
                                            })}
                                        />
                                        <p className='description error pt-32'>
                                            {errors.message && <>{errors.message.message}</>}
                                        </p>
                                    </div>
                                    {alert ?
                                        <p className='description error pb-8'>
                                            Vänligen kryssa in rutan nedan
                                        </p>
                                        : ''}
                                    <ReCAPTCHA
                                        style={{ marginLeft: "49px" }}
                                        sitekey="6LeSXK8oAAAAAL6phpnikn4WMjKI7clWL0KMiukx"
                                        ref={recaptcha}
                                        onChange={onChange}
                                    />
                                    <button style={{ width: 400 }}
                                        onSubmit={() =>
                                            setValue("form", {
                                                shouldValidate: true,
                                                shouldDirty: true
                                            })
                                        }
                                        onClick={() => {
                                         submitEmail();
                                         handleButtonClick("callme", "/confirm"); 
                                             }}

                                        type="submit"
                                        className='column mt-64 mb-32 center'>
                                        Skicka
                                    </button>
                                </form>
                            </div>
                        </section>
                        <div className="column center pt-32 cardL">
                            <div className="center2" style={{ maxWidth: 400 }}>
                                <p className='center2 reactIconPhone' />
                                <h3 className={"center2 pb-16"}> Önskas telefonkontakt?</h3>
                                <button
                                    onClick={() => setSpanclick(!spanclick)} style={{ width: 400 }}>
                                    {spanclick ? <span>Göm</span> : <span >Klick här </span>}
                                </button>
                                <CSSTransition
                                    TelefonMain={nodeRef}
                                    in={spanclick} timeout={300} classNames="fade" unmountOnExit>
                                    <TelefonMain />
                                </CSSTransition>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>
    </>
    );
}
