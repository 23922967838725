import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from "react";
import Spinner from '../../../components/UI/Spinner';
import ReCAPTCHA from "react-google-recaptcha"
import { Helmet } from "react-helmet";

export const TelefonMain = () => {

    const form = useForm({
        defaultValues: {
            phone: "",
            name: "",
        }
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        // Handle form submission here
    };

    const navigate = useNavigate();

    // spinner
    const [loading, setLoading] = useState(false);

    //recaptch
    const recaptcha = useRef();

    useEffect(() => {
        const phoneNumber = "0762-896 803";

        document.getElementById("phone").textContent = phoneNumber;
    }, []);

    const [alert, setAlert] = useState(false);

    ///submit to backend

    const submitEmail = handleSubmit(async (data, data2) => {
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
            setAlert(true)
        } else {

            const verifyResponse = await fetch("https://ayalin.se/verify", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ captchaValue }),
            });

            const verifyData = await verifyResponse.json();


            if (verifyData.success) {
                // reCAPTCHA validation was successful, proceed with sending the email
                setLoading(true);
                try {
                    const sendResponse = await fetch("https://ayalin.se/callMe", {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json",
                        },
                        body: JSON.stringify({ data }),
                    });

                    onSubmit({
                        name: data.name,
                        phone: data.phone,
                    });

                    navigate("/confirmPhone");
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            }
        }
    });

    function onChange(value) {
    }


    const handleButtonClick = async (eventName, route) => {
        try {
            await fetch(`http://localhost:5000${route}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eventName,
                    route,
                }),
            });
        } catch (error) {
            console.error('Error sending CAPI request:', error);
        }
    }

    return (<>
        {
            loading ?

                <Spinner />
                :


                <div className="row">
                    <Helmet >
                        <title>
                            Kom i kontakt med oss.
                        </title>
                        <meta
                            name="Ring mig"
                            content=" Om du vill att vi ska ringa upp dig"
                        />

                    </Helmet>
                    <div className={"center pt-32"}>


                        <form onSubmit={handleSubmit((onSubmit))}>
                            <h3 className='center'>Vill du ringa till oss?</h3>
                            <h3 className='secondary p-16'>8.00-17.00</h3>
                            <h3 className='secondary pb-32' id="phone"></h3>
                            <h3 className='pt-32 pb-32'>Ska vi ringa upp dig iställlllet?</h3>


                            <label htmlFor="phonenumber">Ditt telefonnummer</label>

                            <input
                                className='center mt-8'
                                style={{ maxWidth: 350 }}
                                placeholder={"Vänligen skriv ditt telefonnummer här"}
                                {...register('phone', {
                                    required: 'Vänligen skriv ditt telefonnummer',
                                    maxLength: {
                                        value: 23,
                                        message: 'För långt telefonnummer',
                                    },
                                    minLength: {
                                        value: 10,
                                        message: 'För kort telefonnummer',
                                    },
                                })}
                            />
                            <p className='description error pt-8'>
                                {errors.phone && <>{errors.phone.message}</>}
                            </p>



                            <label htmlFor="name">Ditt namn</label>


                            <input
                                className='center mt-8'
                                style={{ maxWidth: 350 }}
                                placeholder={"Vänligen skriv ditt namn här"}
                                {...register('name', {
                                    required: 'Vänligen skriv ditt namn',

                                })}
                            />
                            <p className='description error pt-8'>
                                {errors.name && <>{errors.name.message}</>}
                            </p>


                            {alert ?
                                <p className='description error pb-8'>
                                    Vänligen kryssa in rutan nedan</p> : ''}


                            <ReCAPTCHA
                                style={{ marginLeft: "49px" }}
                                sitekey="6LeSXK8oAAAAAL6phpnikn4WMjKI7clWL0KMiukx"
                                ref={recaptcha}
                                onChange={onChange} />

<button
    style={{ width: 400 }}
    onClick={() => {
        submitEmail();
        handleButtonClick("callme", "/confirmPhone"); 
    }}
    type="submit"
    className='column mt-64 mb-32 center'
>
    Ring mig
</button>
                        </form>
                    </div>
                </div>
        }
    </>
    );
};

