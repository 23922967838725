import HomeMobile from "../Home/HoemPageMobile/HomeMobile";
import HomeDesktop from "./HomeDesktop";

import React, { useContext, useEffect, useState } from 'react';

export default function HomePage() {

    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 880;



    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    return (
        <div>
            {isMobile ? <HomeMobile /> : <HomeDesktop />}
        </div>
    );
}

