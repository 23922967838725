import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Parallax } from 'react-scroll-parallax';
import './styles.css';
import { FaPhone } from "react-icons/fa";
import us from '../../../assets/us.apng'
import scroll from '../../../assets/scroll.apng'
import we from '../../../assets/we.apng'
import pic4 from '../../../assets/4.png'
import pic1 from '../../../assets/startpageFirst.jpg'

function HomeMobile() {

    //ease in element

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 250;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    ///hide scroll icon

    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    const listenToScroll = () => {
        let heightToHideFrom = 200;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll > heightToHideFrom) {
            isVisible && setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };



    const handleButtonClick = async (eventName, route) => {
        try {
            await fetch(`http://localhost:5000${route}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eventName,
                    route,
                }),
            });
        } catch (error) {
            console.error('Error sending CAPI request:', error);
        }
    }
    



    return (

        <section className="body center">

            <div className="firstM pb-64 center">
                {/* <img src={pic1} style={{ width: "100vw" }} /> */}
                <div className='textfirst center2 pt-64'>
                    <h2 className="firstOverTextM center">Vi hjälper</h2>
                    <p className="firstOverTextM2 center mt-16 mb-32">Om ni behöver tömma ett dödsbo  så finns vi här för dig </p>
                    <div className='center jcb'>
                        <span className='buttonstr2 flex cols center2 jcc'>


                            <a href="tel:+46762896803"
                                alt="Phone number to call us">


                            <button style={{ maxWidth: '260px' }} alt="Button to call" onClick={() => handleButtonClick('PhoneButtonClick', '/call')}>
                                    <FaPhone />   Ring oss direkt
                                </button>
                            </a>



                            <Link to="contact">
                            <button style={{ maxWidth: '260px' }} alt="CTA our contacts" onClick={() => handleButtonClick('contact', '/contact')}>
                                    Till kontaktuppgifter
                                </button>
                            </Link>

                        
              
                        </span>
                    </div>
                </div>

            </div>

            <span id="container2" className='center'>

                {
                    isVisible
                    &&
                    <div id="hide">
                        <img src={scroll} style={{ marginTop: '20px', width: '40px' }} alt="scroll icon" />

                    </div>
                }
            </span>


            <div className="reveal  center fade-bottom pt-64 pb-64">
                <Parallax speed={2}>
                    <img src={us} style={{ width: '50px' }} alt="what we can offer you" />
                    <h2>Vad vi erbjuder</h2>

                    <p className='textblock pt-16'>

                        Ett komplett tjänst för er som behöver snabbt och effektiv finna ett lösning på dödsbo.

                    </p>

                </Parallax>



            </div>


            <div className="reveal center mc fade-bottom pt-64 pb-128">
                <Parallax speed={2}>
                    <img src={we} style={{ marginTop: '40px', width: '50px' }} alt="Why you should choose us" />

                    <h2>Varför välja oss </h2>

                    <p className='textblock pt-16'>
                        Vi  erbjuder ett kompetent hjälp av kollegor som utför arbetet effektivt och professionellt.
                        Vi betalar alltid för dina inventarier vi försöker alltid att erbjuda bästa möjlig pris.
                    </p>
                </Parallax>
            </div>



            <div className="reveal fade-bottom pt-64 pb-64 " >
                <div className='mc flex'>
                    <span className="overlap-div4M">
                        <Parallax speed={2} scale={[0.7, 0.8]}>
                            <span className="overlap-div-color4M" alt="Get in touch with us">
                                Kom i kontakt med oss
                            </span>
                        </Parallax>
                    </span>


                    <Parallax speed={2} scale={[0.7, 0.8]}>
                        <div id="overlap-div423M flex">
                            <Link to="Charity">
                                <button style={{ marginTop: "100px" }} alt="charity button">
                                    Välgörenhet
                                </button>
                            </Link>

                        </div>
                        <div className="overlap-div42M flex">

                            <Link to="Contact">
                                <button style={{ marginTop: "0px" }} alt="contact us button">

                                    Kontaktuppgifter
                                </button>
                            </Link>
                        </div>
                    </Parallax>

                </div>
                <span alt="Vi erbjuder service och helhetslösning inom kvarlåtenskap försäljning av arvegods dödsbo och bostadstömning" >

                    <img src={pic4} className="img4M" style={{ marginTop: "-220px", height: "350px", width: "350px" }} />
                </span>


            </div>





        </section>


    );
};

export default HomeMobile
