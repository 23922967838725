import React, { useEffect, useState } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import fenix from "../../assets/fenix2.svg";
import "./navbar.scss";

function Navbar() {
  const navigate = useNavigate();

  ///fire hamburger menu

  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });


  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);






  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  return (
    <header className="header">
      <div className="header__content">

        <span className="flex jcc">
          {size.width > 820 ?
            <Link to="/"><img src={fenix} style={{ margin: '10px', marginTop: '19px', width: '5vh' }} alt="done" /> </Link>
            : ''}



          <Link to="/" id="logo">

            Ayalin
          </Link>

        </span>



        <nav
          className={`${"header__content__nav"} 
          ${menuOpen && size.width < 768 ? `${"isMenu"}` : ""} 
          }`}
        >

          {menuOpen ?



            <ul>

              <li style={{ color: "#1A7F86" }}>
                <Link to="/contact" onClick={menuToggleHandler}>Boka tid</Link>
              </li>
              <li>
                <Link to="/services" onClick={menuToggleHandler}>Våra tjänster</Link>
              </li>
              <li>
                <Link to="/charity" onClick={menuToggleHandler}>Välgörenhet</Link>
              </li>
              <li>
                <Link to="/about" onClick={menuToggleHandler}>Om oss</Link>
              </li>

            </ul>

            :


            <ul>

              <li style={{ color: "#1A7F86" }}>
                <Link to="/contact" >Boka tid</Link>
              </li>
              <li>
                <Link to="/services" >Våra tjänster</Link>
              </li>
              <li>
                <Link to="/charity" >Välgörenhet</Link>
              </li>
              <li>
                <Link to="/about">Om oss</Link>
              </li>
            </ul>

          }

        </nav>



        <div className="header__content__toggle">
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>


      </div>
    </header>
  );
}

export default Navbar;
