import React from "react";
import { Helmet } from "react-helmet";

function VåraTjänster() {
  return (
    <div className="pt-64 cardST">
      <Helmet >
        <title>
          Vilka tjänster vi kan erbjuda dig
        </title>
        <meta
          name="Våra tjänster vi kan erbjuda dig"
          content="Här är våra tjänster och beskrivningen på hur processen går till"
        />

      </Helmet>

      <div className="center3 " alt=" Vi erbjuder tjänster inom kvarlåtenskap försäljning av arvegods dödsbo och bostadstömning ">


        <h2 className="pb-32">Våra tjänster och arbetssätt</h2>
        <p className="pb-64 textblock" alt="Beskrivning av våra tjänster">
          <span id="capital">V</span>i på Ayalin står alltid för professionellt bemötande, förståelse, och kvalitet i alla etapper av vårt arbete. Därför arbetar vi med stor engagemang och omsorg där kunden alltid står i fokus när vi skräddarsyr en lösning.
        </p>

        <div className=" center3 ml-16 mb-16 mr-16" style={{ margin: "0 auto", marginTop: "10vh", marginBottom: "1vh", marginLeft: "10px", marginRight: "10px" }}>
          <h2 className="pt-64 pb-32" alt="Beskrivning av hur processen går till">Hur går processen till?</h2>
          <p className="services1 center2" />
          <h3 className="center2 pb-16">Första besök</h3>
          <p className="pb-32 textblock">
            <span id="capital">V</span>i bedömer och värderar inventarier som kan säljas vidare. Därefter kan vi erbjuda er ett pris på plats. Alternativt kan vi göra en kalkyl och dra av kostnaderna för våra tjänster, och sedan göra en utbetalning till önskat konto. Vi kan även hjälpa er att sälja värdefulla saker på auktion.
          </p>

          <p className="serv2 center2 pb-8" />
          <h3 className="center2 pb-16">Bortforsling och sortering</h3>
          <p className="pb-32 textblock">
            <span id="capital">E</span>fter värderingen transporteras de värdefulla sakerna till auktion, eller om vi kommer överens, så köper vi inventarier direkt på plats. De mindre värdefulla sakerna kan du välja att skänka till välgörenhetsorganisationer som hjälper människor som drabbats av kriget i Ukraina.
          </p>

          <p className="serv3 center2 pb-8" />
          <h3 className="center2 pb-16">Flyttstädning</h3>
          <p className="pb-64 textblock">
            <span id="capital">N</span>är allt är klart utför vi flyttstädning som uppfyller kraven för besiktning eller lägenhetsvisning. Skulle något uppstå och städningen inte godkänns, står vi för de uppkomna kostnaderna.
          </p>
        </div>

      </div>
    </div>


  );
}

export default VåraTjänster;
