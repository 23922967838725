import React from 'react';
import './App.css';
import ReactDOM from 'react-dom';

import App, { WrappedApp } from './App';
import { hydrate, render } from "react-dom";

ReactDOM.render(<WrappedApp />, document.getElementById('root'));

const rootElement = document.getElementById("root");


if (rootElement.hasChildNodes()) {
  hydrate(<WrappedApp />, rootElement);
} else {
  render(<WrappedApp />, rootElement);
}