import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import fenix from "../../assets/fenix2.svg";

function Footer() {
    const [isMobile, setIsMobile] = useState(false);

    const obfuscateEmail = () => {
        const username = 'ayalinsthlm';
        const domain = 'gmail.com';
        return `${username}${String.fromCharCode(64)}${domain}`;
    };

    const email = obfuscateEmail();
    const phoneNumber = "0762-896 803";

    useEffect(() => {

        const handleResize = () => {
            if (window.innerWidth < 1600) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        handleResize(); // Call it initially to set isMobile

        window.addEventListener("resize", handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <hr className="hrwhite" />
            <hr />
            <div className="footer center">
                <div className="main">
                    <div className="row">
                        <div className="footer-header">
                            <h3>Kontakt</h3>
                            <p>Skogsgatan 2 <br />15244 Södertälje </p>
                            <p> <a id="lin" href="https://maps.app.goo.gl/RonhRceppYNH9dWH8" rel="nofollow">Hitta hit</a></p>
                            <p> Kontakttelefon <br />
                                <span id="phone" className='mt-4'>{phoneNumber}</span>
                            </p>
                        </div>
                    </div>

                    <div id="logob">
                        <img
                            src={fenix}
                            style={{
                                margin: '10px',
                                marginTop: '19px',
                                width: isMobile ? '55px' : '95px'
                            }}
                            alt="logo"
                        />
                        <div>
                            <Link to="/" id="logo">
                                Ayalin
                            </Link>
                        </div>
                    </div>

                    <div className="link row">
                        <div className="footer-header">
                            <h3>Länkar</h3>
                        </div>
                        <div className="footer-header">
                            <p>
                                <Link to="/charity" id="lin">Välgörenhet</Link>
                            </p>
                            <p>
                                <Link to="/about" id="lin">
                                    Om oss
                                </Link>
                            </p>
                            <span>
                                <p>
                                    <a href={`mailto:${email}`} id="lin" rel="nofollow">
                                        Feedback
                                    </a>
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <h6 className="pb-16">© Copyright {new Date().getFullYear()} Ayalin.</h6>
                </div>
            </div>
        </>
    );
}

export default Footer;
