import React, { useState, useEffect, useRef } from "react";
import YoutubeEmbed from './YoutubeEmbed';
import { Helmet } from "react-helmet";

function Charity() {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 1200;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <div className="pt-64 cardST" alt="Välgörenhet i Stockholm och Göteborg">
      <Helmet >
        <title>
          Välgörenhet och Ayalin
        </title>
        <meta
          name="Vårt samarbete med välgörenhetsorganisationer "
          content="Vi jobbar med välgörenhetsorganisationer som hjälper till människor i Ukraina"
        />
      </Helmet>
      <h2 className="center pb-32">Välgörenhet</h2>

      {isMobile ? (
        <div className="center jcc cards" alt="Beskrivning av hur vi samarbetar med välgörenhet och organisationer för att hjälpa Ukraina">
          <span className="card">
            <p className="center pb-32 textblock">
              <span id="capital">V</span>i bryr oss om andra, därför samarbetar vi med organisationer som hjälper människor i nöd. Just nu samarbetar vi mycket med organisationer som hjälper människor att stå ut med konsekvenserna av Ukrainakriget. Vi riktar oss för samarbete med organisationer som jobbar direkt med dessa människor, utan några mellanhänder, alltså sådana som har direkt kontakt med människor i krigsdrabbade regioner.
            </p>
            <div className="center pb-64 jcm">
              <button onClick={handleClick} style={{ maxWidth: 630 }}>
                <span>Skicka donation</span>
              </button>
            </div>
          </span>

          <section className="center">
            <h2>Help Ukraine Stockholm</h2>
            <p className="pt-16 pb-16 textblock m-8 center2">Vår adress är Sveavägen 162 i Stockholm. På den här adressen tar vi gärna emot era gåvor.</p>
            <p className="logosthlm center2" style={{ maxWidth: 230 }} />
          </section>

          <section className="center">
            <h2>Help Ukraine Göteborg</h2>
            <p className="pt-16 pb-16 textblock m-8 center2">Vi finns på Bankogatan 61 i Göteborg. På den här adress tar vi även gärna emot era gåvor.</p>
            <p className="logochar center2" style={{ maxWidth: 230 }} />
          </section>
        </div>
      ) : (
        <div className="center jcc cards">
          <section className="center">
            <h2>Help Ukraine Stockholm</h2>
            <p className="pt-16 pb-16 textblock m-8 center2">Vår adress är Sveavägen 162 i Stockholm. På den här adressen tar vi gärna emot era gåvor.</p>
            <p className="logosthlm center2" style={{ maxWidth: 250 }} />
          </section>
          <span className="card">
            <p className="center pb-32 textblock">
              <span id="capital">V</span>i bryr oss om andra, därför samarbetar vi med organisationer som hjälper människor i nöd. Just nu samarbetar vi mycket med organisationer som hjälper människor att stå ut med konsekvenserna av Ukrainakriget. Vi riktar oss för samarbete med organisationer som jobbar direkt med dessa människor, utan några mellanhänder, alltså sådana som har direkt kontakt med människor i krigsdrabbade regioner.
            </p>
            <div className="center pb-64 jcm">
              <button onClick={handleClick} style={{ maxWidth: 630 }}>
                <span>Skicka donation</span>
              </button>
            </div>
          </span>
          <section className="center">
            <h2>Help Ukraine Göteborg</h2>
            <p className="pt-16 pb-16 textblock m-8 center2">Vi finns på Bankogatan 61 i Göteborg. Här kan vi även motta era gåvor.</p>
            <p className="logochar center2" style={{ maxWidth: 250 }} />
          </section>
        </div>
      )}

      <h2 className="pt-64 pb-32 center">Resultat av vårt arbete</h2>
      <div className="videoDiv center2 pb-64">
        <div className="p-32">
          <YoutubeEmbed embedId="Q23WPm1zft4" />
        </div>
      </div>
      <br />

      <div ref={ref} className="center pt-64 ml-8 mb-8 mr-8" style={{ margin: "0 auto" }}>
        <div className='textblock secondary pt-32'>
          Vi mottar gärna gåvor från er, som kommer att gå direkt till människor som behöver er hjälp.
        </div>
        <div className="jcm pb-64 pt-16">
          <h3 className="p-16">UVH Stockholm</h3>
          <p className="logoswish center2" style={{ maxWidth: 230 }} />
          <div className="pt-8">
            <h3>Help Ukraine Göteborg</h3>
            <p>SWISH nummer 1230645481</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Charity;
