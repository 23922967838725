import React from 'react';
import { Link } from "react-router-dom";
import done from '../../assets/done.apng'
import { Helmet } from "react-helmet";

const ConfirmPhone = () => {


    return (
        <div className='space' alt="Confirmation of sent email">
            <Helmet >
                <title>
                    Tack för din mail
                </title>
                <meta
                    name="Bäkräftelse"
                    content="Ditt meddelande är nu skickat"
                />

            </Helmet>
            <div className='center m-8 pt-64 pb-64' style={{ minheight: "100vh" }} >
                <div style={{ marginTop: '10%' }}>
                    <img src={done} style={{ marginTop: '50px', width: '50px' }} alt="done" />

                    <h1 className='pt-32 pb-32'>Tack!</h1>


                    <br /> Ditt meddelande är nu skickat, vi ringer upp dig inom kort.</div>
                <br />
                <button style={{ width: 260, }}> <Link to="/">Till startsida</Link></button>
            </div>
        </div>
    );
}
export default ConfirmPhone;