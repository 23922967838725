import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { ParallaxBanner, Parallax } from 'react-scroll-parallax';
import { FaPhone } from "react-icons/fa";
import pic3 from '../../assets/StartPage3rd.png'
import scroll from '../../assets/scroll.apng'
import we from '../../assets/we.apng'
import us from '../../assets/icons/enviro.svg'

import pic4 from '../../assets/4.png'



function HomeDesktop() {

    const [width, setWidth] = useState(window.innerWidth);
    const isMobile = width <= 1920;



    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);





//button action CAPI
const handleButtonClick = async (eventName, route) => {
    try {
        await fetch(`http://localhost:5000${route}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventName,
                route,
            }),
        });
    } catch (error) {
        console.error('Error sending CAPI request:', error);
    }
}




    return (



        <div className='body pb-64'>

            {isMobile ?
                <section className="section mb-64">
                    <div className="first" alt="Vi erbjuder service och helhetslösning inom kvarlåtenskap försäljning av arvegods dödsbo och bostadstömning" >
                        <span className="firstOver flex cols jcc p-16 pr-32">
                            <h1 className="firstOverText" style={{ fontWeight: 900, fontSize: "3.9rem" }}>Vi hjälper</h1>
                            <h2 className="center p-16 primary" style={{ fontWeight: 900 }}>Om ni behöver ta hand om arvegods eller kvarlåtenskap  ,<br /> så finns vi här för er</h2>


                

                            <Link to="Contact">
                            <button className="firstbtn" alt="CTA our contacts" onClick={() => handleButtonClick('contact', '/contact')}>
                                    Till kontaktuppgifter
                                </button>
                            </Link>


          



                        </span>
                    </div>
                </section>

                :


                <section className="section mb-64">
                    <div className="firstXL">
                        <span className="firstOverXL flex cols jcc p-16 pr-32">
                            <h1 className="firstOverTextXL">Vi hjälper</h1>
                            <p className="center p-16 primary firstOverTextL">Om ni behöver ta hand om arvegods eller kvarlåtenskap  ,<br /> så finns vi här för er</p>




                            <Link to="Contact">
                                <button className="firstbtn firstOverText">
                                    Till kontaktuppgifter
                                </button>
                            </Link>


                   


                        </span>
                    </div>
                </section>

            }

            {isMobile ?
                <div className="pt-32">
                    <Parallax speed={-5}>

                        <div className="section2" alt="what we can offer you">
                            <div className="middle-div2"></div>
                            <div className="image-container3">
                                <div className="overlap-div2"><div className="overlap-div-color2">

                                    Vad vi erbjuder </div></div>

                                <div className="overlap-div22">
                                    <span id="capital">E</span>tt  komplett lösning
                                    som täcker alla era behov.
                                    <p>Vi erbjuder ett kostnadsfri värdering av dödsboets egendom.</p>
                                    <p>
                                        Vi köper eller säljer alla dina varor.
                                    </p>
                                    <p>Vidare vi erbjuder flyttstädning och bortforsling av arvegods och kvarlåtenskap. </p>

                                </div>


                                <Parallax speed={-10}>
                                    <img className="img2" />
                                </Parallax>
                            </div>
                        </div>
                    </Parallax>
                </div>
                :

                <section className='jcm center container2'>


                    <h1 className='overlap-div-color21 h4k mb-64'>

                        Vad vi erbjuder
                    </h1>
                    {/* <img src={we} style={{ width: '170px' }} alt="What Ayalin offers to you" className=' mb-64' /> */}


                    <p className='p4k'>

                        <span className='p4k' id="capital">E</span>tt  komplett lösning
                        som täcker alla era behov.
                    </p>
                    <p className='p4k'>Vi erbjuder ett kostnadsfri värdering av dödsboets egendom.</p>
                    <p className='p4k'>
                        Vi köper eller säljer alla dina varor.
                    </p>
                    <p className='p4k' alt="What company offers text description">
                        Vidare vi erbjuder flyttstädning och bortforsling av arvegods och kvarlåtenskap. </p>

                </section>
            }


            {isMobile ?

                <section className="section3 ">
                    <div className="middle-div3"></div>
                    <div className="image-container3">
                        <Parallax speed={5}>
                            <div className="overlap-div3">
                                <div className="overlap-div-color3" style={{ paddingLeft: "45px" }}>
                                    Varför välja oss?
                                </div>
                            </div>

                            <div className="overlap-div32">
                                <p>  <span id="capital">V</span>i  erbjuder ett kompetent hjälp av kollegor som utför arbetet effektivt och professionellt. </p>
                                <p alt=" Short presentation of company ">
                                    Hos oss är du alltid i fokus. Vi jobbar individuellt och erbjuder alltid bästa möjliga pris för dina värdefulla inventarier.

                                </p>
                            </div>
                        </Parallax>

                        {/* <img className="img3" /> */}
                        <img src={pic3} style={{ marginTop: '40px', width: '395px', height: '705px' }} alt="Why choose us" />

                    </div>
                </section>

                :

                <section className='jcm center container3'>

                    <h1 className='overlap-div-color2 h4k mb-64'>
                        Varför välja oss?
                    </h1>
                    <img src={us} style={{ marginTop: '40px', width: '170px' }} alt="Why choose us" />


                    <p className='p4k'>  <span id="capital">V</span>i  erbjuder ett kompetent hjälp av kollegor som utför arbetet effektivt och professionellt. </p>
                    <p className='p4k' alt="Our services and description of company ">
                        Hos oss är du alltid i fokus. Vi jobbar individuellt och erbjuder alltid bästa möjliga pris för dina värdefulla inventarier.

                    </p>

                </section>





            }

            {isMobile ?

                <section className="section4 pt-8 pb-8">
                    <div className="middle-div "></div>
                    <div className="image-container4">
                        <Parallax speed={3}>
                            <div className="overlap-div2"><div className="overlap-div-color2X" alt="Ayalin kontakt">

                                Kom i kontakt med oss </div></div>

                        </Parallax>
                        <Parallax speed={4} scale={[0.9, 1]}>
                            <div className='overlap-span42' alt="Ayalin contact picture">
                                <Link to="Contact">
                                    <button style={{ width: '400px' }}>
                                        Till kontaktuppgifter
                                    </button>
                                </Link>
                                <Link to="Charity">
                                    <button style={{ width: '400px' }}>
                                        Välgörenhet
                                    </button>
                                </Link>
                            </div>
                        </Parallax>

                        {/* <img className="img4" */}
                        <img src={pic4} style={{ width: '510px' }} alt="Contact Ayalin" className=' mb-64' />

                    </div>
                </section>


                :

                <section className='pt-8 pb-8 center3' alt="Vi erbjuder service och helhetslösning inom kvarlåtenskap försäljning av arvegods dödsbo och bostadstömning">

                    <h1 className='overlap-div-color21 h4k mb-128'>
                        Kom i kontakt med oss
                    </h1>

                    <Parallax speed={2} scale={[0.9, 1.3]}>

                        <div className='flex cols mb-128'>
                            <Link to="Contact">
                                <button style={{ width: '700px' }} alt="contact us button">
                                    Till kontaktuppgifter
                                </button>
                            </Link>
                            <Link to="Charity">
                                <button style={{ width: '700px' }} alt="charity button" >
                                    Välgörenhet
                                </button>
                            </Link>
                        </div>
                    </Parallax>

                </section>
            }

        </div>

    );
}

export default HomeDesktop;
