import React, { useRef, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import NotFound from './components/NotFound';
import VåraTjänster from './Pages/VåraTjänster';
import Charity from './Pages/Välgörenhet/Charity';
import BokaTid from './Pages/BokaTid/BokaTid';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import About from './Pages/About';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar';
import ConfirmPage from './Pages/ConfirmPage/ConfirmPage';
import ConfirmPhone from './Pages/ConfirmPage/ConfirmPhone';
import HomePage from './Pages/Home/HomPage';
import { ParallaxProvider } from 'react-scroll-parallax';
import './Transition.css';
import { scrollToTop } from './helpers/TopScreen';
import { Helmet } from "react-helmet";
import MetaPixel from './meta/metaPixel';

function App() {
    const location = useLocation();
    const nodeRef = useRef(null);

    useEffect(() => {
        scrollToTop();
    }, [location]);





    return (
        <div className="app-container">
            <Navbar />
            <MetaPixel/>
            <Helmet >
                <title>
                    Ayalin i Stockholm
                </title>
                <meta
                    name="beskrivningen"
                    content="Vi hjälper dig med dödsbo, kvarlåtenskap, antikvariat och lägenhetstömmning.
"
                />
                <meta
                    name="keywords"
                    content=" Dödsbo, kvarlåtenskap, antikvariat och lägenhetstömmning i Stockholm.
"
                />
            </Helmet>
            <TransitionGroup>
                <CSSTransition
                    // nodeRef={nodeRef}
                    key={location.pathname}
                    classNames="fade"
                    timeout={{ enter: 400, exit: 400 }}
                >
                    <div ref={nodeRef}>
                        <Routes>
                            <Route path="/contact" element={<BokaTid />} />
                            <Route path="/services" element={<VåraTjänster />} />
                            <Route path="/charity" element={<Charity />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/confirm" element={<ConfirmPage />} />
                            <Route path="/confirmPhone" element={<ConfirmPhone />} />
                            <Route path="/" element={<HomePage />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <footer>
                <Footer />
            </footer>
        </div>
    );
}

export function WrappedApp() {
    return (
        <ParallaxProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ParallaxProvider>
    );
}

export default App;
