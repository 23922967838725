import { useForm } from 'react-hook-form';
import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha"

import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/UI/Spinner';
import TelefonMobile from './TelefonMobile.jsx'
import { Helmet } from "react-helmet";

export const tid = ['07.00', '08.00', '09.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00', 'Vet ej just nu']
export const boarea = ['10 kvm', '20kvm', '30kvm', '40kvm', '50kvm', '60kvm', '70kvm', '80kvm', '90kvm', '100kvm', '120kvm', '150 > kvm ']
export const antalrum = ['1', '2', '3', '4', '5', '6', '7', '8 >']


export default function BokaTidMobile() {

    const form = useForm({
        defaultValues: {
            name: "",
            message: "",
            phone: "",
            email: "",
            createdAt: "",
            antalrum: "",
            boarea: "",
            hour: "",
            store: "",
            store2: ""
        }
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();


    const onSubmit = (data) => {
    }

    const navigate = useNavigate();




    //recaptch
    const recaptcha = useRef();

    // spinner

    const [loading, setLoading] = useState(false);


    ///check for error

    const [alert, setAlert] = useState(false);



    ///submit to backend

    const submitEmail = handleSubmit(async (data, data2) => {
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
            setAlert(true)
        } else {

            const verifyResponse = await fetch("https://ayalin.se/verify", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ captchaValue }),
            });

            const verifyData = await verifyResponse.json();


            if (verifyData.success) {
                // reCAPTCHA validation was successful, proceed with sending the email
                setLoading(true);
                try {
                    const sendResponse = await fetch("https://ayalin.se/send", {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json",
                        },
                        body: JSON.stringify({ data }),
                    });

                    onSubmit({
                        name: data.name,
                        message: data.message,
                        phone: data.phone,
                        email: data.email,
                        createdAt: data.createdAt,
                        antalrum: data.antalrum,
                        boarea: data.boarea,
                        hour: data.hour,
                        store: data.store,
                        store2: data.store2,
                    });

                    navigate("/confirm");
                } catch (error) {
                    console.error("An error occurred:", error);
                    alert("An error occurred. Please try again later.");
                }
            }
        }
    });



    //avalible on list
    const [checked2, setChecked2] = React.useState(false);
    const handleChange2 = () => {
        setChecked2(!checked2);
    };
    const [checked, setChecked] = React.useState(false);
    const handleChange = () => {
        setChecked(!checked);
    };

    function onChange(value) {
    }



    return (<>
        {

            loading ?
                <Spinner />
                :
                <>
                    <div className='center'>
                        <Helmet >
                            <title>
                                Kom i kontakt med oss.
                            </title>
                            <meta
                                name="Skicka en förfråga eller ring oss"
                                content=" Kom i konktakt med oss här eller boka tid för ett besök samt ringa oss"
                            />
                        </Helmet>
                        <h2 className={"center pt-32 pb-64"}>
                            Kom i kontakt med oss
                        </h2>

                        <div className='cardSM'>

                            <div className={"center2 pt-16"} style={{ maxWidth: 330 }}>
                                <p className='center2 reactIconMail  mb-32' />

                                <h3 className={"center3 pt-16 pb-16"}>Skicka iväg en förfråga</h3>

                                <form onSubmit={handleSubmit((d) => console.log(d))}>


                                    <div className='center3'>
                                        <label htmlFor="username">Ditt namn</label>
                                        <input
                                            placeholder="Skriv ditt namn här"

                                            className='mt-8'
                                            style={{ maxWidth: 270 }}
                                            id="username"
                                            {...register("username", {
                                                required: true,
                                                validate: {
                                                    minLength: (v) => v.length >= 2,
                                                    matchPattern: (v) => /^[a-zA-Z0-9_]+$/.test(v),
                                                },
                                            })}
                                        />

                                        {errors.username?.type === "required" && (
                                            <p className='description error'>Vänligen skriv ditt namn</p>
                                        )}

                                        {errors.username?.type === "minLength" && (
                                            <p className='description error'>The username should have at least 5 characters</p>
                                        )}

                                        {errors.username?.type === "matchPattern" && (
                                            <p className='description error'>Username must contain only letters, numbers and _</p>
                                        )}
                                    </div>


                                    <div className='center'>
                                        <label className='center'>Email</label>
                                        <input
                                            className='mt-8'
                                            style={{ maxWidth: 270 }}
                                            id="email"
                                            placeholder="Din e-mail skrivs här"


                                            {...register("email", {
                                                required: "Vänligen skriv din e-mail",
                                                validate: {
                                                    minLength: (m) => m.length >= 5
                                                        || "The email should have at least 5 characters",
                                                    maxLength: (v) =>
                                                        v.length <= 50 || "The email should have at most 50 characters",
                                                    matchPattern: (v) =>
                                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                        "Email address must be a valid address",
                                                },
                                            })}
                                        />



                                        <p className='description error pt-8'>
                                            {errors.email && <>{errors.email.message}</>}
                                        </p>
                                    </div>


                                    <div className='center'>
                                        <label> Telefonnummer </label>
                                        <input
                                            className='mt-8'
                                            style={{ maxWidth: 270 }}
                                            placeholder="Ditt telefonnummer skrivs här"
                                            role='phone'
                                            {...register('phone', {
                                                required: 'Vänligen fyll i telefonnummer',
                                                maxLength: {
                                                    value: 13,
                                                    message: 'För långt telefonnumer',
                                                },
                                            })}
                                        />


                                        <p className='description error pt-8'>
                                            {errors.phone && <>{errors.phone.message}</>}
                                        </p>
                                    </div>



                                    <div className='center'>

                                        <label htmlFor="createdAt ">Önskad datum</label>
                                        <input
                                            className='mt-8'
                                            style={{ maxWidth: 270, height: 27 }}

                                            id="select"
                                            type="date"
                                            {...register('createdAt', {
                                                required: 'Vänligen fyll i önskad datum',
                                            })}
                                        />

                                        <p className='description error pt-32'>
                                            {errors.createdAt && <>{errors.createdAt.message}</>}
                                        </p>
                                    </div>


                                    <div className='center2 mt-8 center '>
                                        <label htmlFor="hour">Önskad tid</label>

                                        <select
                                            style={{ width: 310 }}
                                            className='mt-8 center'
                                            id="select"


                                            {...register("hour", { required: 'Vänligen välj önskad tid' })}>
                                            <option value='' >--Välj tid nedan--</option>
                                            {tid.map((item) => {
                                                return (
                                                    <option key={item}>
                                                        {item}
                                                    </option>
                                                )
                                            })
                                            }
                                        </select>


                                        <p className='description error pt-8'>
                                            {errors.hour && <>{errors.hour.message}</>}
                                        </p>

                                    </div>


                                    <div className='center'>


                                        <h3 className={"pt-32 pb-8"}>Utrymmen som ska bortforsas</h3>


                                        <div className='mt-8'>
                                            <div>
                                                <label htmlFor="select">Antal rum</label>

                                            </div>

                                            <select style={{ width: 310 }}
                                                className='mt-8'
                                                id="select"


                                                {...register("antalrum", { required: 'Vänligen välj antal rum' })}>
                                                <option value=''>--Välj antal rum nedan--</option>
                                                {antalrum.map((item) => {
                                                    return (
                                                        <option key={item}>
                                                            {item}
                                                        </option>
                                                    )
                                                })
                                                }
                                            </select>
                                            <p className='description error pt-8'>
                                                {errors.antalrum && <>{errors.antalrum.message}</>}
                                            </p>

                                        </div>

                                    </div>


                                    <div className='center'>
                                        <div>
                                            <label htmlFor="createdAt">Uppskattad area på utrymmed</label>

                                        </div>

                                        <select style={{ width: 310 }}
                                            className='mt-8'
                                            id="select"

                                            {...register("boarea", { required: 'Vänligen välj boarea' })}>
                                            <option value=''>--Välj boarea--</option>
                                            {boarea.map((item) => {
                                                return (
                                                    <option key={item}>
                                                        {item}
                                                    </option>
                                                )
                                            })
                                            }
                                        </select>
                                        <p className='description error pt-8'>
                                            {errors.boarea && <>{errors.boarea.message}</>}
                                        </p>
                                    </div>


                                    <div className='center'>
                                        <label htmlFor="store"> Finns förråd i huset?</label>
                                        <br />

                                        <input
                                            className='checkbox'
                                            id="store"
                                            type="checkbox"
                                            {...register('store',)}

                                        />

                                    </div>

                                    <div className='center mt-32'>
                                        <label>  Finns källare i huset? </label>

                                        <br />
                                        <input
                                            className='checkbox'
                                            id="store2"
                                            type="checkbox"
                                            {...register('store2',)}
                                        />

                                    </div>


                                    <div className='center pt-32'>

                                        <label>Ditt meddelande </label>

                                        <textarea style={{ maxWidth: 315 }} rows={8} cols={48} className='mt-8' id="message" placeholder="  Ditt meddelande skrivs här"


                                            {...register('message', {
                                                required: 'Vänligen skriv ett meddelnade',
                                                minLength: {
                                                    value: 13,
                                                    message: 'För kort meddelande',
                                                },
                                            })}
                                        />


                                        <p className='description error pt-32'>
                                            {errors.message && <>{errors.message.message}</>}
                                        </p>
                                    </div>

                                    {alert ?
                                        <p className='description error pb-8'>
                                            Vänligen kryssa in rutan nedan</p> : ''}

                                    <ReCAPTCHA
                                        style={{ marginLeft: "15px" }}
                                        sitekey="6LeSXK8oAAAAAL6phpnikn4WMjKI7clWL0KMiukx"
                                        ref={recaptcha}

                                        onChange={onChange} />

                                    <button
                                        className='mb-32 mt-32 center'

                                        style={{ width: 315 }}

                                        onSubmit={() =>
                                            setValue("form", {
                                                shouldValidate: true,
                                                shouldDirty: true
                                            })
                                        }
                                        onClick={submitEmail}
                                        type="submit"

                                    >
                                        Skicka
                                    </button>
                                </form>
                            </div>
                        </div>

                        <br />

                        <div className='cardSM pt-32'>


                            <TelefonMobile />
                        </div>


                    </div>


                </>}
    </>

    );
}
