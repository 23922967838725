import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from "react";
import Spinner from '../../../components/UI/Spinner';
import ReCAPTCHA from "react-google-recaptcha"
import { Helmet } from "react-helmet";


const TelefonMobile = () => {

    const form = useForm({
        defaultValues: {
            name: "",
            phone: "",

        }
    })


    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        // Handle form submission here
    };


    const navigate = useNavigate();


    //Hide number 
    useEffect(() => {
        const phoneNumber = "0762-896 803";

        document.getElementById("phone").textContent = phoneNumber;
    }, []);

    // spinner
    const [loading, setLoading] = useState(false);

    //recaptch
    const recaptcha = useRef();

    //error captcha
    const [alert, setAlert] = useState(false);

    const submitEmail = handleSubmit(async (data, data2) => {
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
            setAlert(true)
        } else {

            const verifyResponse = await fetch("https://ayalin.se/verify", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ captchaValue }),
            });

            const verifyData = await verifyResponse.json();


            if (verifyData.success) {
                // reCAPTCHA validation was successful, proceed with sending the email
                setLoading(true);
                try {
                    const sendResponse = await fetch("https://ayalin.se/callMe", {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json",
                        },
                        body: JSON.stringify({ data }),
                    });

                    onSubmit({
                        name: data.name,
                        phone: data.phone,
                    });

                    navigate("/confirmPhone");
                } catch (error) {
                    console.error("An error occurred:", error);
                }
            }
        }
    });



    function onChange(value) {
    }

    return (<>
        {
            loading ?
                <Spinner />
                :


                <div className="row">
                    <Helmet >
                        <title>
                            Kom i kontakt med oss.
                        </title>
                        <meta
                            name="Ring mig"
                            content=" Om du vill att vi ska ringa upp dig"
                        />

                    </Helmet>
                    <div className={"center2"} style={{ maxWidth: 330 }}>
                        <form onSubmit={handleSubmit((onSubmit))}>
                            <p className='center2 reactIconPhone' />

                            <h3 className='center pb-16'>Vill du ringa till oss?</h3>
                            <h3 className='secondary'>8.00-17.00</h3>
                            <br />
                            <h3 className='secondary' id="phone"></h3>
                            <h3 className='pt-32 pb-32'>Ska vi ringa upp dig istället?</h3>


                            <label htmlFor="phonenumber">Ditt telefonnummer</label>

                            <input
                                style={{ maxWidth: 270 }}

                                className='center mt-8'

                                placeholder={"Ditt telefonnummer skrivs här"}
                                {...register('phone', {
                                    required: 'Vänligen skriv ditt telefonnummer',
                                    maxLength: {
                                        value: 23,
                                        message: 'För långt telefonnummer',
                                    },
                                    minLength: {
                                        value: 10,
                                        message: 'För kort telefonnummer',
                                    },
                                })}
                            />
                            <p className='description error pt-8'>
                                {errors.phone && <>{errors.phone.message}</>}
                            </p>



                            <label htmlFor="name">Ditt namn</label>


                            <input
                                style={{ maxWidth: 270 }}

                                className='center mt-8'
                                placeholder={"Ditt namn skrivs här"}
                                {...register('name', {
                                    required: 'Vänligen skriv ditt namn',

                                })}
                            />
                            <p className='description error pt-8'>
                                {errors.name && <>{errors.name.message}</>}
                            </p>

                            {alert ?
                                <p className='description error pb-8'>
                                    Vänligen kryssa in rutan nedan</p> : ''}


                            <ReCAPTCHA
                                style={{ marginLeft: "15px" }}
                                ref={recaptcha}
                                sitekey="6LeSXK8oAAAAAL6phpnikn4WMjKI7clWL0KMiukx"
                                onChange={onChange} />


                            <button
                                className='mb-32 mt-32 center'

                                style={{ width: 315 }}
                                onSubmit={() =>
                                    setValue("form", {
                                        shouldValidate: true,
                                        shouldDirty: true
                                    })
                                }
                                onClick={submitEmail}
                                type="submit"
                            >

                                Ring mig
                            </button>
                        </form>
                    </div>
                </div>
        }
    </>
    );
};

export default TelefonMobile