import React from 'react';
import { FiAperture } from "react-icons/fi";
import { FiSettings } from "react-icons/fi";

function Spinner() {
    return (
        <body>
            <div className='spacesm center' style={{ paddingTop: "10%" }} >
                <FiSettings className="loading-icon" />
            </div>


        </body>
    );
};

export default Spinner;