import React from "react";
import Navbar from "../Navbar";

function NotFound() {
  return (
    <section style={{ height: "100vh" }}>
      <h2 className=" jcm center pt-64" style={{ fontSize: "80pt" }}>404</h2>
      <h1 className=" jcm center pt-64"> PAGE NOT FOUND</h1>
      <h3 style={{ fontSize: "20pt" }} className="center p-16 primary"> Sidan kan tyvärr inte hittas. Vänligen uppdatera eller återgå till tidigare sida </h3>

    </section>
  );
}

export default NotFound;
